<template>
   <div class="col-12 px-0 mb-1" ref="fornecedor">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ fornecedor.nomeFantasia }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ fornecedor.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tag font-8 me-1"></i>
                              <span>{{ fornecedor.observacoes == null || String(fornecedor.observacoes).trim().length == 0 ? 'Sem observações' : fornecedor.observacoes }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col-6 col-lg-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                           <span> {{ fornecedor.endereco == null || fornecedor.endereco.rua == null || String(fornecedor.endereco.rua).trim().length == 0 == null ? 'Sem endereço' : (fornecedor.endereco.rua +', '+ fornecedor.endereco.numero +' - '+ fornecedor.endereco.bairro) }}</span>
                        </p>
                     </div>
                     <div class="col-6 col-lg-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-address-card color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> CPF/CNPJ:</strong>
                           <span> {{ fornecedor.cpfCnpj == null || String(fornecedor.cpfCnpj).trim().length == 0 ? 'Sem CPF/CNPJ' : fornecedor.cpfCnpj }}</span>
                        </p>
                     </div>
                     <div class="col-lg-4 d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-envelope color-theme font-10 me-1"></i><strong class="subtitle me-1"> Email:</strong>
                           <span> {{ fornecedor.email == null ? 'Sem certificado' : fornecedor.email }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar fornecedores/beneficiários')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Fornecedor',
   props: ['fornecedor', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar fornecedores/beneficiários')) {
            this.$emit('editar', this.fornecedor)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir fornecedor?',
            text: 'O fornecedor será permanentemente excluído.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'fiscal/deleteFornecedor',
                  params: {
                     id: this.fornecedor.id
                  }
               }).then(() => {
                  this.$refs.fornecedor.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Fornecedor excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>